import { useCallback, useContext, useMemo } from 'react'
import { UserDocument } from '../../../../UserDocuments/userDocuments.slice'
import {
  PrefixedUserDocumentCategoryIdentifier,
  UserDocumentCategoryIdentifier,
} from '../../../../Admin/UserDocumentCategories/userDocumentCategory.constants'
import {
  Document,
  EndOfYearAdminReviewStep,
  EndOfYearReviewStepStatus,
  PayrollDocumentsStep,
} from '../../types'
import { replaceDocument } from '../helpers'
import { AdminYearEndReviewContext } from '../context'
import { useAdminDocuments } from '../hooks'
import BookkeepingReviewSection from '../bookkeeping-review-section'
import { DocumentRows, DocumentRow } from '../document-row'
import OtherDocuments from '../other-documents'
import StepNotRelevant from '../step-not-relevant'
import HeardPayrollRow from '../heard-payroll-row'

const { formW3 } = UserDocumentCategoryIdentifier
const { pending, complete } = EndOfYearReviewStepStatus
const STEP_TYPE = EndOfYearAdminReviewStep.docsPayroll

export type PayrollDocumentsSectionProps = {
  step: PayrollDocumentsStep | null
  userDocuments: UserDocument[]
}
const PayrollDocumentsSection = ({
  step,
  userDocuments,
}: PayrollDocumentsSectionProps) => {
  const { taxYear, updateStep, payrollProfile } = useContext(
    AdminYearEndReviewContext
  )

  const { getFileIfExists, sortedDocuments, updateStepUsing } =
    useAdminDocuments<Document>({
      step: STEP_TYPE,
      stepContextDocuments: step?.context?.documents,
      userDocuments,
    })

  const payrollJournal = useMemo(
    () => PrefixedUserDocumentCategoryIdentifier.payrollJournal(taxYear),
    [taxYear]
  )

  const getDescription = useCallback(
    (document: Document) => {
      const provider = document.provider ?? 'Other'
      switch (document.categoryCode) {
        case formW3:
          return `${provider} - Form W3`
        case payrollJournal:
          return `${provider} - Payroll Journal`
        default:
          return `${provider} - ${document.categoryCode}`
      }
    },
    [payrollJournal]
  )

  const onConfirmClick = useCallback(() => {
    const status = step?.status === complete ? pending : complete
    const documents =
      status === complete
        ? step?.context?.documents?.map((doc) => ({
            ...doc,
            requestReUpload: false,
            adminNote: '',
          }))
        : undefined
    updateStep({
      status,
      step: STEP_TYPE,
      context: documents ? { ...step?.context, documents } : undefined,
    })
  }, [step, updateStep])

  const saveUpdatedDocument = useCallback(
    (updatedDoc: Document) => {
      if (!step?.context) return
      updateStepUsing({
        ...step.context,
        documents: replaceDocument(updatedDoc, step.context.documents),
      })
    },
    [step?.context, updateStepUsing]
  )

  const stepHasDocuments = useMemo(() => {
    const hasDocuments =
      sortedDocuments.length || step?.context?.otherDocumentIds?.length
    return hasDocuments || payrollProfile
  }, [step?.context, payrollProfile, sortedDocuments])

  const updatedByUser = useMemo(
    () => sortedDocuments.some((r) => r.updatedByUser),
    [sortedDocuments]
  )

  return (
    <BookkeepingReviewSection
      sectionTitle="Payroll"
      className="eoy-admin__payroll-documents"
      status={step?.status ?? EndOfYearReviewStepStatus.pending}
      updatedByUser={updatedByUser}
      confirmationTitle="Adjustments completed"
      confirmationDescription=""
      minHeight={70}
      testId="payroll-documents"
      onConfirmClick={step ? onConfirmClick : undefined}
    >
      {step ? (
        <>
          <DocumentRows>
            {payrollProfile && (
              <>
                <HeardPayrollRow
                  categoryCodes={[formW3]}
                  stepDocuments={step.context?.documents}
                  getFileIfExists={getFileIfExists}
                  onRequestReUpload={saveUpdatedDocument}
                />
                <HeardPayrollRow
                  categoryCodes={[payrollJournal]}
                  stepDocuments={step.context?.documents}
                  getFileIfExists={getFileIfExists}
                  onRequestReUpload={saveUpdatedDocument}
                />
              </>
            )}

            {sortedDocuments
              .filter((doc) => doc.provider !== 'Heard')
              .map((doc) => (
                <DocumentRow
                  key={`${doc.provider} - ${doc.categoryCode}`}
                  description={getDescription(doc)}
                  document={doc}
                  file={getFileIfExists(doc)}
                  testId={`${doc.provider}_${doc.categoryCode}`}
                  onRequestReUpload={saveUpdatedDocument}
                />
              ))}
          </DocumentRows>

          <OtherDocuments
            userDocuments={userDocuments.filter((ud) =>
              step.context?.otherDocumentIds?.some((id) => id === ud.id)
            )}
            showHeader
          />

          {!stepHasDocuments && (
            <StepNotRelevant text="User did not upload any documents." />
          )}
        </>
      ) : (
        <StepNotRelevant />
      )}
    </BookkeepingReviewSection>
  )
}

export default PayrollDocumentsSection

import { useCallback, useContext, useMemo } from 'react'
import { UserDocument } from '../../../UserDocuments/userDocuments.slice'
import { AdminYearEndReviewContext } from './context'
import {
  Document,
  EndOfYearAdminReviewStep,
  EndOfYearReviewStepStatus,
  IDocumentsStepContext,
  Receipt,
} from '../types'
import { sortDocuments } from './helpers'

const { pending, userActionRequired } = EndOfYearReviewStepStatus

export const useAdminDocuments = <T extends Document | Receipt>({
  step,
  stepContextDocuments = [],
  userDocuments = [],
}: {
  step: EndOfYearAdminReviewStep
  stepContextDocuments?: T[]
  userDocuments: UserDocument[]
}) => {
  const { updateStep } = useContext(AdminYearEndReviewContext)

  const getFileIfExists = useCallback(
    (document: T) => {
      const userDocument = userDocuments.find(
        (ud) => ud.id === document.documentId
      )
      if (userDocument?.signedUrl && userDocument?.fileDescription) {
        return {
          name: userDocument.fileDescription,
          href: userDocument.signedUrl,
        }
      }
      return undefined
    },
    [userDocuments]
  )

  const updateStepUsing = useCallback(
    (context: IDocumentsStepContext) => {
      const stepStatus = context.documents?.some((doc) => doc.requestReUpload)
        ? userActionRequired
        : pending
      return updateStep({
        step,
        status: stepStatus,
        context: { ...context },
      })
    },
    [updateStep, step]
  )

  const sortedDocuments = useMemo(
    () => sortDocuments(stepContextDocuments),
    [stepContextDocuments]
  )

  return {
    getFileIfExists,
    updateStepUsing,
    sortedDocuments,
  }
}

import Text from '../../../../components/BaseComponents/Text'
import { useEffect, useState, useCallback } from 'react'
import { Grid } from 'semantic-ui-react'
import { GridRowColumn } from '../../../../components/BaseComponents'
import { StepHeaders } from '../../../../components/StepHeader'
import { ReviewStepCard } from '../../../../components/Finances/ReviewStep'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { useNavigate, useParams } from 'react-router-dom'
import { userGetBookkeepingSubmission } from '../../end-of-year-review/actions'
import {
  EndOfYearAdminReviewStep,
  Document,
} from '../../end-of-year-review/types'
import { IStep } from '../step-map'
import './styles.scss'

export const FollowUp = ({
  setIsStepComplete,
}: {
  setIsStepComplete: (value: boolean) => void
}) => {
  const { year } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [isPendingBankStatements, setIsPendingBankStatements] =
    useState<boolean>(true)
  const [isPendingTransaction, setIsPendingTransaction] =
    useState<boolean>(true)
  const [isPendingDocuments, setIsPendingDocuments] = useState<boolean>(false)

  const [showPendingBankStatements, setShowPendingBankStatements] =
    useState<boolean>(false)
  const [showPendingTransaction, setShowPendingTransaction] =
    useState<boolean>(false)
  const [showPendingDocuments, setShowPendingDocuments] =
    useState<boolean>(false)

  const followUpStep: IStep = {
    id: 'follow-up-transactions',
    name: 'Review "Uncategorized" transactions',
    route: 'follow-up-transactions',
    component: null,
    props: null,
    isAlwaysComplete: true,
    isRequired: () => true,
  }

  const statementsStep: IStep = {
    id: 'follow-up-statements',
    name: 'Upload missing statements',
    route: 'follow-up-statements',
    component: null,
    props: null,
    isAlwaysComplete: true,
    isRequired: () => true,
  }

  const yearEndStep: IStep = {
    id: 'follow-up-documents',
    name: 'Upload year-end documents',
    route: 'follow-up-documents',
    component: null,
    props: null,
    isAlwaysComplete: true,
    isRequired: () => true,
  }

  const setReviewIndex = (route: string) => {
    navigate(`/taxes/annual/review-and-close-books/${year}/${route}`)
  }

  const getAllStepContext = useCallback(async () => {
    //Get Payroll Providers
    const allContext = await userGetBookkeepingSubmission()(dispatch)
    const steps = allContext?.steps
    if (steps === undefined) return
    for (const step of steps) {
      switch (step.step) {
        case EndOfYearAdminReviewStep.uploadMissingStatements:
          if (step.status === 'user_action_required') {
            setShowPendingBankStatements(true)
          }
          if ((step.context as { updatedByUser: boolean }).updatedByUser) {
            setIsPendingBankStatements(false)
          }
          break
        case EndOfYearAdminReviewStep.transactions:
          if (step.status === 'user_action_required') {
            setShowPendingTransaction(true)
          }
          if ((step.context as { updatedByUser: boolean }).updatedByUser) {
            setIsPendingTransaction(false)
          }
          break
        case EndOfYearAdminReviewStep.docsPayroll:
        case EndOfYearAdminReviewStep.docsContractorPayments:
        case EndOfYearAdminReviewStep.docsPreviousTaxReturn:
          if (step.status === 'user_action_required') {
            setShowPendingDocuments(true)
          }
          for (const doc of (step.context as { documents: Document[] })
            .documents) {
            if (doc.requestReUpload && !doc.updatedByUser) {
              setIsPendingDocuments(true)
            }
          }
          break
        case EndOfYearAdminReviewStep.docsProcessingFees:
        case EndOfYearAdminReviewStep.docsAssetsOver2500:
          if (step.status === 'user_action_required') {
            setShowPendingDocuments(true)
          }
          for (const doc of (
            step.context as {
              receipts: { requestReUpload: boolean; updatedByUser: boolean }[]
            }
          ).receipts) {
            if (doc.requestReUpload && !doc.updatedByUser) {
              setIsPendingDocuments(true)
            }
          }
          break
        default:
          break
      }
    }
  }, [dispatch])

  useEffect(() => {
    getAllStepContext()
  }, [getAllStepContext])

  useEffect(() => {
    if (isPendingBankStatements && isPendingTransaction && isPendingDocuments) {
      setIsStepComplete(false)
    }
    if (
      (!isPendingBankStatements || !showPendingBankStatements) &&
      (!isPendingTransaction || !showPendingTransaction) &&
      (!isPendingDocuments || !showPendingDocuments)
    ) {
      setIsStepComplete(true)
    }
  }, [
    isPendingBankStatements,
    isPendingTransaction,
    isPendingDocuments,
    setIsStepComplete,
    showPendingBankStatements,
    showPendingTransaction,
    showPendingDocuments,
  ])

  return (
    <StepHeaders
      imageSrc="https://heard-images.s3.amazonaws.com/assets/bookkeeping.svg"
      imageAlt="coins"
      imageWidth={180}
      imageHeight={180}
      title="Follow-up on your books"
      description=""
      customColor="yellow"
    >
      <Grid>
        <GridRowColumn className="align-left">
          <Text>
            The bookkeeping team has marked these items as needing additional
            follow-up from you. Please complete them ASAP so we can complete
            your end-of-year bookkeeping soon.
          </Text>
        </GridRowColumn>
        <GridRowColumn />
      </Grid>
      <div
        className="group-text-card"
        style={{
          display:
            showPendingBankStatements || showPendingTransaction
              ? 'flex'
              : 'none',
        }}
      >
        <Text>CLOSE YOUR BOOKS</Text>
      </div>
      <div
        style={{
          width: '100%',
          display: showPendingTransaction ? 'block' : 'none',
        }}
      >
        <ReviewStepCard
          key={'follow-up-transactions'}
          step={followUpStep}
          setReviewIndex={setReviewIndex}
          isPending={isPendingTransaction}
          pendingText="The bookkeeping team couldn’t categorize some of your transactions. Please add a note to provide more context."
        />
      </div>
      <div
        style={{
          marginTop: '20px',
          width: '100%',
          display: showPendingBankStatements ? 'block' : 'none',
        }}
      >
        <ReviewStepCard
          key={'follow-up-statements'}
          step={statementsStep}
          setReviewIndex={setReviewIndex}
          isPending={isPendingBankStatements}
          pendingText="Some of your bank statements were missing or incorrect. Please upload the correct statements."
        />
      </div>
      <div
        className="group-text-card"
        style={{ display: showPendingDocuments ? 'flex' : 'none' }}
      >
        <Text>YEAR-END DOCUMENTS</Text>
      </div>
      <div
        style={{
          width: '100%',
          display: showPendingDocuments ? 'flex' : 'none',
        }}
      >
        <ReviewStepCard
          key={'follow-up-documents'}
          step={yearEndStep}
          setReviewIndex={setReviewIndex}
          isPending={isPendingDocuments}
          pendingText="Some of your year-end documents were missing or incorrect. Please upload the correct documents."
        />
      </div>
    </StepHeaders>
  )
}

import { useEffect, useMemo, useState, useCallback } from 'react'
import { Grid, Divider, Icon } from 'semantic-ui-react'
import { NavButtons } from '../../end-of-year-review/nav-buttons'
import moment from 'moment'

import {
  Card,
  GridRowColumn,
  Text,
  Alert,
} from '../../../../components/BaseComponents'
import { useAppDispatch } from '../../../../utils/typeHelpers'
import { Transaction } from '../../../../reducers/admin/allTransactions.slice'
import {
  distinctTransactionsByDateDESC,
  selectUncategorizedTransactionsNotNeedClarification,
} from '../../../Transactions/transactions.selectors'
import { TransactionCard } from '../../../Transactions/Lists/transaction-card'
import { TransactionHeader } from '../../../Transactions/Lists/transaction-header'
import { StepHeaders } from '../../../../components/StepHeader'
import { useReselector } from '../../../../utils/sharedHooks'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchTransactionCategoriesIfNeeded } from '../../../Reports/reports.slice'
import { fetchFilteredUserTransactions } from '../../../Transactions/transactions.slice'
import {
  userGetBookkeepingStep,
  userUpdateBookkeepingStepContext,
} from '../../end-of-year-review/actions'
import {
  EndOfYearAdminReviewStep,
  IDocumentsStepContext,
} from '../../end-of-year-review/types'
import './styles.scss'

interface IUnclarifiedTransactionProps {
  uncatTransactions?: Transaction[]
}
interface uncatStepContext extends IDocumentsStepContext {
  adminNote?: string
}

export const UnclarifiedTransactions = () => {
  const { year } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [stepContext, setStepContext] = useState<uncatStepContext | undefined>()

  useEffect(() => {
    dispatch(fetchTransactionCategoriesIfNeeded())
  }, [dispatch])

  useEffect(() => {
    dispatch(
      fetchFilteredUserTransactions({
        startDate: moment()
          .year(parseInt(year ?? '0'))
          .startOf('year'),
        endDate: moment()
          .year(parseInt(year ?? '0'))
          .endOf('year'),
      })
    )
  }, [dispatch, year])

  const onComplete = useCallback(async () => {
    await userUpdateBookkeepingStepContext(
      EndOfYearAdminReviewStep.unclarifiedTransactions,
      EndOfYearAdminReviewStep.unclarifiedTransactions,
      {
        ...stepContext,
        updatedByUser: true,
      }
    )(dispatch)
    navigate(`/taxes/annual/review-and-close-books/${year}/follow-up`)
  }, [dispatch, navigate, stepContext, year])

  const getAllStepContext = useCallback(async () => {
    //Get Payroll Providers
    const allContext = await userGetBookkeepingStep(
      EndOfYearAdminReviewStep.unclarifiedTransactions
    )(dispatch)
    if (allContext?.context) {
      setStepContext(allContext.context)
    }
  }, [dispatch])

  useEffect(() => {
    getAllStepContext()
  }, [getAllStepContext])

  const unclarifiedTransactions = useReselector(
    selectUncategorizedTransactionsNotNeedClarification,
    year
  )
  const getUnclarifiedTransactions = (
    transactions: Transaction[]
  ): IUnclarifiedTransactionProps =>
    transactions.reduce(
      (acc, transaction) => {
        acc.uncatTransactions.push(transaction)
        return acc
      },
      {
        uncatTransactions: [] as Transaction[],
      }
    )

  const uncatTransactions = useMemo(() => {
    return getUnclarifiedTransactions(unclarifiedTransactions).uncatTransactions
  }, [unclarifiedTransactions])
  const transactionIds = useMemo(
    () => [...(uncatTransactions ?? []).map((t) => t.id)],
    [uncatTransactions]
  )

  const [intialUncategorizedTxns, setintialUncategorizedTxns] = useState<
    Transaction[]
  >([])
  useEffect(() => {
    if ((uncatTransactions ?? []).length && !intialUncategorizedTxns.length) {
      // Note - the above logic assumes uncategorized transactions and needs clarification transactions have been fetched
      // from the server by a higher component. Otherwise we could run into timing issues and would need a loading flag
      setintialUncategorizedTxns(
        distinctTransactionsByDateDESC(uncatTransactions ?? [])
      )
    }
  }, [intialUncategorizedTxns.length, uncatTransactions])

  const updatedTransactionsIds = useMemo(
    () =>
      [...intialUncategorizedTxns].flatMap((t) =>
        !transactionIds.includes(t.id) ? t.id : []
      ),
    [transactionIds, intialUncategorizedTxns]
  )

  return (
    <div className="eoy-review_wrapper">
      <header className="header">
        <Text>{year} End of Year Review</Text>
        <div className="autosave-wrapper">
          <Icon name="check" style={{ color: '#457634' }} />
          <Text style={{ color: '#457634' }}>Autosaved</Text>
        </div>
      </header>
      <Grid>
        <GridRowColumn centerContent className="header-container">
          <StepHeaders
            imageSrc="https://heard-images.s3.amazonaws.com/assets/notepad.svg"
            imageAlt="notepad"
            imageWidth={180}
            imageHeight={180}
            title="How should we categorize these transactions?"
            description=""
            customColor="green"
          >
            <Grid>
              <GridRowColumn className="align-left">
                <Text>
                  Please provide clarity on the transactions below. Add a note
                  to provide context, or select a category.
                </Text>
              </GridRowColumn>
              {(stepContext?.adminNote ?? []).length > 0 && (
                <GridRowColumn width={12} className="tooltip-grid">
                  <Alert
                    title="Note from Bookkeeper"
                    style={{ width: '520px', padding: '40px' }}
                    customIcon="none"
                    contentStyle={{ textAlign: 'left' }}
                    type="acknowledge"
                  >
                    <Text style={{ color: '#AC590C', marginTop: '20px' }}>
                      {stepContext?.adminNote}
                    </Text>
                  </Alert>
                </GridRowColumn>
              )}
            </Grid>
          </StepHeaders>
        </GridRowColumn>
        <Grid.Row />
        {!intialUncategorizedTxns.length && (
          <GridRowColumn>
            <Card backgroundColor="lightGreen">
              <Text>
                All transactions have been categorized. You may continue to the
                next step.
              </Text>
            </Card>
          </GridRowColumn>
        )}

        {Boolean(intialUncategorizedTxns.length) && (
          <>
            <TransactionHeader />
            <GridRowColumn className="grid-body">
              {intialUncategorizedTxns.map((t) => (
                <TransactionCard
                  key={t.id}
                  transactionId={t.id}
                  backgroundColor={
                    updatedTransactionsIds.includes(t.id)
                      ? 'lightGreen'
                      : undefined
                  }
                />
              ))}
            </GridRowColumn>
          </>
        )}
      </Grid>
      <Divider style={{ marginTop: 50 }} />

      <NavButtons
        isStepComplete={unclarifiedTransactions.length === 0}
        stepBack={() => {
          navigate(`/taxes/annual/review-and-close-books/${year}/follow-up`)
        }}
        stepForward={() => {
          onComplete()
        }}
        saveAndExit={() => {
          navigate('/taxes/annual')
        }}
      />
    </div>
  )
}

export default UnclarifiedTransactions

import { ReactNode, useState } from 'react'
import { Divider } from 'semantic-ui-react'
import {
  thin,
  regular,
  solid,
} from '@fortawesome/fontawesome-svg-core/import.macro'

import {
  Button,
  Card,
  Checkbox,
  Icon,
  IconButton,
  Link,
  Popup,
  Text,
} from '../../BaseComponents'
import { StackedIcon } from '../../BaseComponents/Icon'
import { UserDocument } from '../../../features/UserDocuments/userDocuments.slice'
import { Document } from '../../../features/Bookkeeping/end-of-year-review/types'
import styles from './styles.module.scss'
import type { Colors } from '../../../styles/theme'
import DeleteDocumentModal from '../../../features/Taxes/AnnualTaxes/TaxChecklist/Documents/DeleteDocumentModal'

type PartialDoc = Pick<UserDocument, 'id' | 'fileDescription' | 'signedUrl'>

export interface IDocumentUploadCardProps {
  name: string
  bookkeeperNote?: string
  description: ReactNode
  explanation?: ReactNode
  documents?: PartialDoc[]
  handleModalToggle?: () => void
  handleUploadBtn?: () => void
  hasFileUploadError?: boolean
  href?: string
  linkText?: string
  requiredDocCount?: number
  setChecked?: () => void
  notRelevant?: boolean
  status?: 'none' | 'disabled' | 'success'
  cardBackgroundColor?: keyof typeof Colors
  readOnly?: boolean
  hideNoUploadIndicator?: boolean
  rightContent?: ReactNode
  isRequiredReviewed?: boolean | Document
  isFollowUp?: boolean
}
const icons = {
  disabled: {
    icon: solid('xmark'),
    backgroundColor: 'gray',
  },
  success: {
    icon: solid('check'),
    backgroundColor: 'green',
  },
} as const

const bgColor = {
  none: 'natural',
  disabled: 'stone40',
  success: 'lightGreen',
  error: 'lightRed',
} as const

const UploadedDocumentLine = ({
  id,
  fileDescription,
  signedUrl,
  readOnly,
}: { readOnly?: boolean } & PartialDoc) => {
  const [openModal, setOpenModal] = useState(false)

  return (
    <div className={styles.document}>
      {!signedUrl && <Text className={styles.docName}>{fileDescription}</Text>}
      {signedUrl && (
        <Link href={signedUrl} className={styles.docName}>
          {fileDescription}
        </Link>
      )}
      {!readOnly && (
        <IconButton
          onClick={() => setOpenModal(true)}
          icon={regular('trash-can')}
          size="lg"
        />
      )}
      <DeleteDocumentModal
        open={openModal}
        close={() => setOpenModal(false)}
        documentId={id}
      />
    </div>
  )
}

export const DocumentUploadCard = ({
  name,
  description,
  explanation,
  bookkeeperNote,
  documents = [],
  handleUploadBtn,
  hasFileUploadError = false,
  href,
  linkText = 'View instructions',
  requiredDocCount = 0,
  notRelevant = false,
  setChecked,
  status = 'none',
  isRequiredReviewed,
  cardBackgroundColor,
  readOnly = false,
  hideNoUploadIndicator = false,
  rightContent,
  isFollowUp = false,
}: IDocumentUploadCardProps) => {
  if (
    isFollowUp &&
    typeof isRequiredReviewed === 'object' &&
    !isRequiredReviewed?.requestReUpload
  ) {
    return null
  }
  return (
    <Card
      backgroundColor={
        typeof isRequiredReviewed === 'boolean' || !isRequiredReviewed
          ? cardBackgroundColor || bgColor[status]
          : typeof isRequiredReviewed === 'object' &&
              !isRequiredReviewed?.requestReUpload
            ? cardBackgroundColor || bgColor[status]
            : bgColor['error']
      }
    >
      <div className={styles.card}>
        <div>
          {typeof isRequiredReviewed !== 'object' || !isRequiredReviewed ? (
            status === 'none' ? (
              <Icon
                color="gray"
                icon={thin('circle')}
                size="3x"
                title={`${status}-status-icon`}
              />
            ) : (
              <StackedIcon
                title={`${status}-status-icon`}
                color="white"
                size="1x"
                backgroundIcon={solid('circle')}
                backgroundSize={'3x'}
                {...icons[status]}
              />
            )
          ) : !isRequiredReviewed?.requestReUpload ? (
            <StackedIcon
              title={`${status}-status-icon`}
              color="white"
              size="1x"
              backgroundIcon={solid('circle')}
              backgroundSize={'3x'}
              {...icons['success']}
            />
          ) : (
            <StackedIcon
              title={`${status}-status-icon`}
              color="white"
              size="1x"
              backgroundIcon={solid('circle')}
              backgroundSize={'3x'}
              {...icons['disabled']}
            />
          )}
        </div>
        <div className={styles.centerColumn}>
          <Text as="h3">{name}</Text>
          <Text className={styles.description}>{description}</Text>
          {explanation && (
            <Text className={styles.explanation}>{explanation}</Text>
          )}
          {href && (
            <div className={styles.instructions}>
              <Link href={href} newPage>
                {linkText}
              </Link>
              <Icon
                icon={regular('square-arrow-up-right')}
                className={styles.instructionsIcon}
              />
            </div>
          )}

          {bookkeeperNote && (
            <>
              <Text as="h3" color="red" className={styles.reuploadDoc}>
                <strong>Please reupload this document.</strong>
              </Text>

              <Text color="red">Bookkeeper Note: {bookkeeperNote}</Text>
            </>
          )}

          {documents.length > 0 && (
            <>
              <Divider />

              {documents?.map((doc) => (
                <UploadedDocumentLine
                  key={doc.id}
                  {...doc}
                  readOnly={readOnly}
                />
              ))}

              {requiredDocCount > 1 && (
                <Text className={styles.docCount}>
                  Uploaded {documents.length} of {requiredDocCount} documents
                </Text>
              )}
            </>
          )}
          {readOnly && documents.length === 0 && !hideNoUploadIndicator && (
            <>
              <Divider />
              <Text color="darkGray">
                <i>No Uploads</i>
              </Text>
            </>
          )}
        </div>
        <div className={styles.rightColumn}>
          {!readOnly && (
            <>
              <Button
                className={styles.uploadBtn}
                variant="secondary"
                fullWidth
                onClick={handleUploadBtn}
                disabled={
                  typeof isRequiredReviewed === 'object'
                    ? !isRequiredReviewed?.requestReUpload
                    : false
                }
              >
                <Icon
                  icon={regular('upload')}
                  size="lg"
                  className={styles.uploadIcon}
                />
                Upload
              </Button>

              {hasFileUploadError && (
                <Text color="red" className={styles.error}>
                  <strong>Error: </strong>Your document couldn’t be uploaded.
                  Please try again later.
                </Text>
              )}

              {documents?.length === 0 && setChecked && (
                <Popup
                  hoverable
                  content={
                    <div className={styles.popup}>
                      <Text as="h3" className={styles.header}>
                        <strong>Not relevant</strong>
                      </Text>

                      <Text className={styles.topText}>
                        Check this box only if you’re sure this document doesn’t
                        apply to you.
                      </Text>

                      <Text className={styles.bottomText}>
                        <strong>We recommend double-checking first</strong>
                      </Text>

                      <Text>
                        This could delay your end-of-year bookkeeping and annual
                        tax filing if we find we still need the document.
                      </Text>
                    </div>
                  }
                  trigger={
                    <Checkbox
                      id={name}
                      label="Not relevant"
                      onChange={() => setChecked?.()}
                      checked={notRelevant}
                      variant="default"
                    />
                  }
                />
              )}
            </>
          )}
          {rightContent}
        </div>
      </div>
      {typeof isRequiredReviewed === 'object' &&
        isRequiredReviewed?.requestReUpload && (
          <>
            <Divider />
            <Text color="red" className={styles.success}>
              <strong>Please reupload this document.</strong>
            </Text>
            <Text color="red" className={styles.success}>
              Bookkeeper Note: {isRequiredReviewed.adminNote}
            </Text>
          </>
        )}
    </Card>
  )
}

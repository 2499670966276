import { useCallback, useEffect, useState } from 'react'
import { Grid } from 'semantic-ui-react'
import { FormikProvider, useFormik } from 'formik'
import { DateTime } from 'luxon'

import {
  Alert,
  Button,
  Card,
  FormikDateInput,
  FormikInput,
  FormikTransactionCategoryDropdown,
  getFieldName,
  GridRowColumn,
  makeDateSchema,
  makeNumberSchema,
  makeReqStringSchema,
  Text,
} from '../BaseComponents'
import {
  Transaction,
  TransactionCategoryIdentifier,
} from '../../reducers/admin/allTransactions.slice'
import { centsToDollars, dollarsToCents } from '../../utils/currencyHelpers'
import { useReselector } from '../../utils/sharedHooks'
import { TransactionAccountType } from '../../features/Reports/reports.slice'
import { getUserTransactionById } from '../../features/Transactions/transactions.selectors'
import {
  CREATE_MANUAL_TRANSACTION_KEY,
  createSingleManualTransaction,
  UPDATE_USER_TRANSACTION_KEY,
  updateUserTransaction,
  VALIDATE_DUPLICATE_TRANSACTION_KEY,
  validateDuplicateTransactions,
} from '../../features/Transactions/transactions.slice'
import {
  UPDATE_SINGLE_USER_DOCUMENT_KEY,
  updateSingleUserDocument,
} from '../../features/UserDocuments/userDocuments.slice'
import { DuplicateTransactionsModal } from './DuplicateTransactionsModal'
import { TransactionReceiptsModal } from './TransactionReceiptsModal'
import {
  DATE_FORMATS,
  DATE_FORMATS_LUXON,
  isoToUTCDateTime,
} from '../../utils/dateHelpers'
import { selectFirstErrorMessageForKeys } from '../../reducers/fetch'
import { selectCurrentAnnualTaxYear } from '../../features/Admin/AnnualTaxDetails/annualTaxDetails.selector'
import { useAppDispatch } from '../../utils/typeHelpers'
import { TransactionRuleCategoryType } from '../../reducers/admin/transactionRulesReducer'
import type { Colors } from '../../styles/theme'

const transformFormikData = ({
  date,
  description,
  amountInDollars,
  transactionCategoryId,
}: {
  date: string | undefined
  description: string | undefined
  amountInDollars: number | undefined
  transactionCategoryId: number | null | undefined
}) => ({
  date:
    (date && DateTime.fromFormat(date, DATE_FORMATS_LUXON.INPUT).toISODate()) ||
    '',
  amountInCents: dollarsToCents(amountInDollars),
  description: description || '',
  transactionCategoryId: transactionCategoryId || null,
  type: TransactionRuleCategoryType.business,
})

export const AddTransactionCard = ({
  onClose,
  onSave,
  transactionId,
  categoryType,
  categoryFilter,
  backgroundColor = 'natural',
}: {
  onClose: () => void
  onSave: (transactionId: number) => void
  transactionId?: number | undefined
  categoryType?: TransactionAccountType
  categoryFilter?: TransactionCategoryIdentifier[]
  backgroundColor?: keyof typeof Colors
}) => {
  const dispatch = useAppDispatch()
  const taxYear = useReselector(selectCurrentAnnualTaxYear)
  const transaction = useReselector(getUserTransactionById, transactionId)
  const [receiptIds, setReceiptIds] = useState<number[]>([])
  const [modalOpen, setModalOpen] = useState(false)
  const [duplicateTransactions, setDuplicateTransactions] = useState<
    Transaction[]
  >([])
  const error = useReselector(selectFirstErrorMessageForKeys, [
    CREATE_MANUAL_TRANSACTION_KEY,
    UPDATE_SINGLE_USER_DOCUMENT_KEY,
    UPDATE_USER_TRANSACTION_KEY,
    VALIDATE_DUPLICATE_TRANSACTION_KEY,
  ])

  // Set receipt ids with what is in transaction
  useEffect(() => {
    setReceiptIds(transaction?.receipts?.map((doc) => doc.id) || [])
  }, [transaction?.receipts])

  const saveTransaction = useCallback(
    async (data: {
      date: string
      amountInCents: number
      description: string
      transactionCategoryId: number | null
    }) => {
      let res: Transaction | undefined

      if (transactionId) {
        res = await updateUserTransaction(transactionId, data)(dispatch)
      } else {
        res = await createSingleManualTransaction({
          ...data,
          amountInDollars: centsToDollars(data.amountInCents).toString(),
        })(dispatch)

        // Attach all of the receipts to the new transaction.
        // This is not needed if editing because transactionId is set in the upload file modal in that case
        if (res?.id) {
          await Promise.all(
            receiptIds.map(
              (fileId) =>
                res?.id &&
                updateSingleUserDocument(fileId, {
                  transactionId: res.id,
                })(dispatch)
            )
          )
        }
      }

      if (res) {
        onSave(res.id)
        onClose()
      }
    },
    [dispatch, onClose, onSave, transactionId, receiptIds]
  )
  const formik = useFormik({
    initialValues: {
      description: transaction?.description,
      amountInDollars: transaction?.amountInCents
        ? centsToDollars(transaction.amountInCents)
        : undefined,
      date: transaction?.date
        ? isoToUTCDateTime(transaction.date).toFormat(DATE_FORMATS_LUXON.INPUT)
        : '',
      transactionCategoryId: transaction?.transactionCategoryId,
      receiptIds: transaction?.receipts?.map((doc) => doc.id),
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      const possibleDupes = await validateDuplicateTransactions(
        transformFormikData(values),
        transactionId
      )(dispatch)

      if (possibleDupes && possibleDupes?.length > 0) {
        setDuplicateTransactions(possibleDupes)
        setModalOpen(true)
      } else {
        await saveTransaction(transformFormikData(values))
      }
    },
  })

  const { values, submitForm, isSubmitting } = formik

  return (
    <FormikProvider value={formik}>
      <Card backgroundColor={backgroundColor}>
        <DuplicateTransactionsModal
          transactions={duplicateTransactions}
          open={modalOpen}
          saveAnyway={() => saveTransaction(transformFormikData(values))}
          close={() => setModalOpen(false)}
        />
        <Grid>
          {error && (
            <GridRowColumn>
              <Alert type="error">{error}</Alert>
            </GridRowColumn>
          )}
          <GridRowColumn>
            <Text as="eyebrow" color="forest">
              {`${transactionId ? 'Edit' : 'New'} ${
                categoryType === 'Income' ? 'Income' : 'Expense'
              }`}
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <FormikInput
              name={getFieldName<typeof values>('description')}
              label="Description"
              placeholder="Enter description of income"
              fullWidth
              schema={makeReqStringSchema()}
            />
          </GridRowColumn>
          <Grid.Row>
            <Grid.Column width={7}>
              <FormikTransactionCategoryDropdown
                name={getFieldName<typeof values>('transactionCategoryId')}
                label="Category"
                clearable={false}
                optionStyle="normal"
                fullWidth
                limitToCategoryIdentifiers={categoryFilter}
                transactionYear={taxYear}
                schema={makeReqStringSchema()}
              />
            </Grid.Column>
            <Grid.Column width={5}>
              <FormikDateInput
                name={getFieldName<typeof values>('date')}
                label="Date"
                maxDate={DateTime.fromFormat(
                  `${taxYear}-12-31`,
                  'yyyy-LL-dd'
                ).toJSDate()}
                minDate={DateTime.fromFormat(
                  `${taxYear}-01-01`,
                  'yyyy-LL-dd'
                ).toJSDate()}
                placeholder="MM-DD-YYYY"
                fullWidth
                schema={makeDateSchema({
                  format: DATE_FORMATS.INPUT,
                  strict: true,
                })}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <FormikInput
                name={getFieldName<typeof values>('amountInDollars')}
                placeholder="$20.00"
                label="Amount"
                componentType="number"
                fullWidth
                schema={makeNumberSchema({
                  allowedDecimals: 2,
                  allowNegative: categoryType === 'Expenses',
                  maxNumber: categoryType === 'Expenses' ? -0.01 : undefined,
                  minNumber: categoryType === 'Income' ? 0.01 : undefined,
                })}
              />
            </Grid.Column>
          </Grid.Row>
          <GridRowColumn>
            <Text as="h3">Receipt</Text>
          </GridRowColumn>
          <GridRowColumn short>
            <TransactionReceiptsModal
              transaction={transaction}
              receiptIds={receiptIds}
              setReceiptIds={setReceiptIds}
            />
          </GridRowColumn>
          <Grid.Row>
            <Grid.Column width={11} />
            <Grid.Column width={2} verticalAlign="middle">
              <Button variant="actionLink" fullWidth onClick={onClose}>
                Cancel
              </Button>
            </Grid.Column>
            <Grid.Column width={3}>
              <Button
                variant="secondary"
                fullWidth
                onClick={submitForm}
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Save
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </FormikProvider>
  )
}

export default AddTransactionCard

import { useCallback, useContext, useMemo } from 'react'
import { UserDocument } from '../../../../UserDocuments/userDocuments.slice'
import {
  Document,
  EndOfYearAdminReviewStep,
  EndOfYearReviewStepStatus,
  ProcessingFeeDocumentsStep,
} from '../../types'
import { replaceDocument } from '../helpers'
import { AdminYearEndReviewContext } from '../context'
import { useAdminDocuments } from '../hooks'
import BookkeepingReviewSection from '../bookkeeping-review-section'
import { DocumentRows, DocumentRow } from '../document-row'
import OtherDocuments from '../other-documents'
import StepNotRelevant from '../step-not-relevant'

const { pending, complete } = EndOfYearReviewStepStatus
const STEP_TYPE = EndOfYearAdminReviewStep.docsProcessingFees

export type ProcessingFeeDocumentsSectionProps = {
  step: ProcessingFeeDocumentsStep | null
  userDocuments: UserDocument[]
}
const ProcessingFeeDocumentsSection = ({
  step,
  userDocuments,
}: ProcessingFeeDocumentsSectionProps) => {
  const { updateStep } = useContext(AdminYearEndReviewContext)
  const { getFileIfExists, sortedDocuments, updateStepUsing } =
    useAdminDocuments<Document>({
      step: STEP_TYPE,
      stepContextDocuments: step?.context?.documents,
      userDocuments,
    })

  const onConfirmClick = useCallback(() => {
    const status = step?.status === complete ? pending : complete
    const documents =
      status === complete
        ? step?.context?.documents?.map((doc) => ({
            ...doc,
            requestReUpload: false,
            adminNote: '',
          }))
        : undefined
    updateStep({
      status,
      step: STEP_TYPE,
      context: documents ? { ...step?.context, documents } : undefined,
    })
  }, [step, updateStep])

  const stepHasDocuments = useMemo(
    () => sortedDocuments.length || step?.context?.otherDocumentIds?.length,
    [step?.context, sortedDocuments]
  )

  const updatedByUser = useMemo(
    () => sortedDocuments.some((r) => r.updatedByUser),
    [sortedDocuments]
  )

  return (
    <BookkeepingReviewSection
      sectionTitle="Processing fees"
      className="eoy-admin__processing-fee-documents"
      status={step?.status ?? EndOfYearReviewStepStatus.pending}
      updatedByUser={updatedByUser}
      confirmationTitle="Adjustments completed"
      confirmationDescription=""
      minHeight={70}
      testId="processing-fee-documents"
      onConfirmClick={step ? onConfirmClick : undefined}
    >
      {step ? (
        <>
          <DocumentRows>
            {sortedDocuments.map((doc) => (
              <DocumentRow
                key={`${doc.provider} - ${doc.name}`}
                description={`${doc.provider} - ${doc.name}`}
                document={doc}
                file={getFileIfExists(doc)}
                testId={`${doc.provider}_${doc.name}`}
                onRequestReUpload={(updatedDoc) => {
                  updateStepUsing({
                    ...step.context,
                    documents: replaceDocument(
                      updatedDoc,
                      step.context.documents
                    ),
                  })
                }}
              />
            ))}
          </DocumentRows>

          <OtherDocuments
            userDocuments={userDocuments.filter((ud) =>
              step.context?.otherDocumentIds?.some((id) => id === ud.id)
            )}
            showHeader
          />

          {!stepHasDocuments && (
            <StepNotRelevant text="User did not upload any documents." />
          )}
        </>
      ) : (
        <StepNotRelevant />
      )}
    </BookkeepingReviewSection>
  )
}

export default ProcessingFeeDocumentsSection
